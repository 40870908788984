import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const WisdomApplicationPage = () => {
  let language = "en"

  return (
    <Layout pageTitle="procedure-page" language={language}>
      <SEO
        lang={language}
        title="Apply Here For The Wisdom For Wisdom Program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="joshua-tree-content program-page-wisdom">
        <div className="columns wisdom-application top-section white-back has-text-centered">
          <div className="column">
            <h1>Wisdom For Wisdom Application</h1>
          </div>
        </div>

        <section className="application">
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column">
              <div className="application-wrapper">
                {/* <Seamless src="/w4wForm.js" /> */}
              </div>
            </div>
            <div className="column is-6"></div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default WisdomApplicationPage
